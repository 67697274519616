import { pubSub } from '../lib/pubsub';

let hasAnimations = false;

const NUM_METABALLS = 3;

const canvas = document.createElement('canvas');
const gl = canvas.getContext('webgl');
canvas.setAttribute('id', 'c');
canvas.setAttribute('aria-hidden', 'true');
document.body.appendChild(canvas);

const scale = window.devicePixelRatio;
const canvasWidth = Math.floor(canvas.clientWidth * scale);
const canvasHeight = Math.floor(canvas.clientHeight * scale);

canvas.width = canvasWidth;
canvas.height = canvasHeight;

gl.viewport(0, 0, canvas.width, canvas.height);

/**
 * Shaders
 */

// Utility to fail loudly on shader compilation failure
function compileShader(shaderSource, shaderType) {
  const shader = gl.createShader(shaderType);
  gl.shaderSource(shader, shaderSource);
  gl.compileShader(shader);

  if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
    throw 'Shader compile failed with: ' + gl.getShaderInfoLog(shader);
  }

  return shader;
}

const vertexShaderSource = `
    attribute vec2 position;
    
    void main() {
      // position specifies only x and y.
      // We set z to be 0.0, and w to be 1.0
      gl_Position = vec4(position, 0.0, 1.0);
    }
 `;

const fragmentShaderSource = `
    precision highp float;
    uniform vec3 metaballs[${NUM_METABALLS}];
    const float WIDTH = ${canvasWidth}.0;
    const float HEIGHT = ${canvasHeight}.0;
    const vec3 COLOR = vec3(0.28, 0.63, 0.66);
    
    void main(){
        float x = gl_FragCoord.x;
        float y = gl_FragCoord.y;
        float v = 0.0;
        for (int i = 0; i < ${NUM_METABALLS}; i++) {
            vec3 mb = metaballs[i];
            float dx = mb.x - x;
            float dy = mb.y - y;
            float r = mb.z;
            v += r*r/(dx*dx + dy*dy);
        }
        if (v > 1.0) {
            gl_FragColor = vec4(COLOR, 1.0);
        } else {
            gl_FragColor = vec4(0.0, 0.0, 0.0, 0.0);
        }
    }
 `;

const vertexShader = compileShader(vertexShaderSource, gl.VERTEX_SHADER);
const fragmentShader = compileShader(fragmentShaderSource, gl.FRAGMENT_SHADER);

const program = gl.createProgram();
gl.attachShader(program, vertexShader);
gl.attachShader(program, fragmentShader);
gl.linkProgram(program);
gl.useProgram(program);

/**
 * Geometry setup
 */

// Set up 4 vertices, which we'll draw as a rectangle
// via 2 triangles
//
//   A---C
//   |  /|
//   | / |
//   |/  |
//   B---D
//
// We order them like so, so that when we draw with
// gl.TRIANGLE_STRIP, we draw triangle ABC and BCD.
const vertexData = new Float32Array([
  -1.0,
  1.0, // top left
  -1.0,
  -1.0, // bottom left
  1.0,
  1.0, // top right
  1.0,
  -1.0, // bottom right
]);
const vertexDataBuffer = gl.createBuffer();
gl.bindBuffer(gl.ARRAY_BUFFER, vertexDataBuffer);
gl.bufferData(gl.ARRAY_BUFFER, vertexData, gl.STATIC_DRAW);

/**
 * Attribute setup
 */

// Utility to complain loudly if we fail to find the attribute

function getAttribLocation(program, name) {
  const attributeLocation = gl.getAttribLocation(program, name);
  if (attributeLocation === -1) {
    throw 'Can not find attribute ' + name + '.';
  }
  return attributeLocation;
}

// To make the geometry information available in the shader as attributes, we
// need to tell WebGL what the layout of our data in the vertex buffer is.
const positionHandle = getAttribLocation(program, 'position');
gl.enableVertexAttribArray(positionHandle);
gl.vertexAttribPointer(
  positionHandle,
  2, // position is a vec2
  gl.FLOAT, // each component is a float
  gl.FALSE, // don't normalize values
  2 * 4, // two 4 byte float components per vertex
  0, // offset into each span of vertex data
);

/**
 * Simulation setup
 */

let metaballs = [];

for (let i = 0; i < NUM_METABALLS; i++) {
  const radius = Math.random() * (canvasHeight / 5);
  metaballs.push({
    x: Math.random() * (canvasWidth - 2 * radius) + radius,
    y: Math.random() * (canvasHeight - 2 * radius) + radius,
    vx: Math.random() * 10,
    vy: Math.random() * 10,
    r: radius,
  });
}

/**
 * Uniform setup
 */

// Utility to complain loudly if we fail to find the uniform
function getUniformLocation(program, name) {
  const uniformLocation = gl.getUniformLocation(program, name);
  if (uniformLocation === -1) {
    throw 'Can not find uniform ' + name + '.';
  }
  return uniformLocation;
}
const metaballsHandle = getUniformLocation(program, 'metaballs');

/**
 * Simulation step, data transfer, and drawing
 */

const step = function () {
  if (hasAnimations) {
    // Update positions and speeds
    for (let i = 0; i < NUM_METABALLS; i++) {
      const mb = metaballs[i];

      mb.x += mb.vx / 5;
      if (mb.x - mb.r < 0) {
        mb.x = mb.r + 0.01;
        mb.vx = Math.abs(mb.vx);
      } else if (mb.x + mb.r > canvasWidth) {
        mb.x = canvasWidth - mb.r;
        mb.vx = -Math.abs(mb.vx);
      }
      mb.y += mb.vy / 5;
      if (mb.y - mb.r < 0) {
        mb.y = mb.r + 1;
        mb.vy = Math.abs(mb.vy);
      } else if (mb.y + mb.r > canvasHeight) {
        mb.y = canvasHeight - mb.r;
        mb.vy = -Math.abs(mb.vy);
      }
    }

    // To send the data to the GPU, we first need to
    // flatten our data into a single array.
    const dataToSendToGPU = new Float32Array(3 * NUM_METABALLS);
    for (let i = 0; i < NUM_METABALLS; i++) {
      const baseIndex = 3 * i;
      const mb = metaballs[i];
      dataToSendToGPU[baseIndex + 0] = mb.x;
      dataToSendToGPU[baseIndex + 1] = mb.y;
      dataToSendToGPU[baseIndex + 2] = mb.r;
    }
    gl.uniform3fv(metaballsHandle, dataToSendToGPU);

    gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);

    requestAnimationFrame(step);
  }
};

pubSub.subscribe((payload) => {
  hasAnimations = payload.hasAnimations;
  if (hasAnimations) {
    step();
  }
});

step();
