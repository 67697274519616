const l10n = {
  moreinfo: {
    en: 'more information',
    de: 'mehr Informationen',
  },
  open: {
    en: 'open',
    de: 'offen',
  },
  pending: {
    en: 'pending',
    de: 'laufend',
  },
  closed: {
    en: 'closed',
    de: 'geschlossen',
  },
  SEARCH: {
    en: 'Search',
    de: 'Suche',
  },
  SEARCH_LABEL: {
    en: 'Search in programmes',
    de: 'Programme durchsuchen',
  },
  CATEGORIES_LABEL: {
    en: 'Categories',
    de: 'Kategorien',
  },
  PLACEHOLDER: {
    en: 'Search in programmes ...',
    de: 'Programme durchsuchen ...',
  },
  RESET_BTN_LABEL: {
    en: 'reset',
    de: 'zurücksetzen',
  },
  NO_FILTER_RESULT: {
    en: 'No results',
    de: 'Keine Ergebnisse',
  },
  FILTER_RESULTS: {
    en: 'results found',
    de: 'Ergebnisse gefunden',
  },
  FILTER_RESULT: {
    en: 'result found',
    de: 'Ergebnis gefunden',
  },
  SUBMISSION_DEADLINES: {
    en: 'Submission deadlines',
    de: 'Antragsfristen',
  },
};

export const $t = (lang, name) =>
  l10n[name]?.[lang] ? l10n[name][lang] : name;
