import { pubSub } from '../lib/pubsub.js';
import { isPreferingReducedMotion } from '../lib/utils.js';

const STORAGE_NAME = 'fondsdaku-prefers-reduce-motion';
const button = document.querySelector('#reduce-motion-button');
let hasAnimations = true;

try {
  hasAnimations = localStorage.getItem(STORAGE_NAME) == 'true';
} catch (error) {
  console.warning(error);
} finally {
  if (localStorage.getItem(STORAGE_NAME) === null) {
    localStorage.setItem(STORAGE_NAME, true);
    // console.info(`LocalStorage with name "${STORAGE_NAME}" is null.`);
  }
}

const stateChange = (hasAnimations) => {
  document.body.classList.toggle('has-animations', hasAnimations);
  button.classList.toggle('play', !hasAnimations);
  button.setAttribute(
    'aria-label',
    hasAnimations ? 'Animationen deaktivieren' : 'Animationen aktivieren',
  );
  pubSub.publish({ hasAnimations });
  // console.log('Published message: ', { hasAnimations });
};

const handleButtonAction = (e) => {
  if (e.type === 'keydown' && e.code !== 'Enter') return;
  hasAnimations = !hasAnimations;
  localStorage.setItem(STORAGE_NAME, hasAnimations);
  stateChange(hasAnimations);
};

button.addEventListener('click', handleButtonAction);
button.addEventListener('keydown', handleButtonAction);

if (isPreferingReducedMotion) {
  hasAnimations = false;
  button.closest('li').style.display = 'none';
}

// initial toggle + published message
stateChange(hasAnimations);
