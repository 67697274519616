import { svg } from 'lit';

export const icons = {
  list: svg`
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      aria-hidden="true"
    >
      <g fill="currentColor" fill-rule="evenodd">
        <rect width="20" height="4" />
        <rect width="20" height="4" y="8" />
        <rect width="20" height="4" y="16" />
      </g>
    </svg>
  `,
  grid: svg`
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      aria-hidden="true"
    >
      <g fill="currentColor" fill-rule="evenodd">
        <rect width="9" height="9" />
        <rect width="9" height="9" x="11" />
        <rect width="9" height="9" x="11" y="11" />
        <rect width="9" height="9" y="11" />
      </g>
    </svg>
  `,
  download: svg`
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      aria-hidden="true"
    >
      <g
        fill="currentColor"
        fill-rule="evenodd"
        stroke="currentColor"
        stroke-linejoin="round"
        stroke-width="2"
        transform="translate(4 3)"
      >
        <path d="M4,11 L12,20 L20,11 M12,0 L12,20" />
        <line x2="24" y1="24" y2="24" />
      </g>
    </svg>
  `,
};
