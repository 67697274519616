/** @type {HTMLElement} */
const toggler = document.querySelector('.toggler');

/** @type {HTMLElement} */
const closeBtn = document.querySelector('.navigation-wrapper .close');

/** @type {HTMLElement} */
const navElement = document.querySelector('.navigation-element');

/** @type {HTMLElement} */
const rootNavList = document.querySelector('.navigation ul');

/** @type {NodeListOf<HTMLAnchorElement>} */
const navLinks = document.querySelectorAll(
  '.navigation li:not(.hide-in-menu) a',
);

/**
 * @param {HTMLAnchorElement} link
 * @param {Number} level
 * @returns {HTMLButtonElement}
 */
const createBackBtn = (link, level) => {
  const btn = document.createElement('button');
  btn.innerText = link.textContent;
  btn.addEventListener('click', (event) => {
    event.preventDefault();
    const rootLevelLinks = rootNavList.querySelectorAll(
      `[data-level="${level}"] > a`,
    );
    rootLevelLinks.forEach((link) => {
      link.removeAttribute('inert');
    });
    const parentList = btn.closest('ul');
    rootNavList.style.transform = `translateX(${-1 * (level - 1) * 100}%`;
    rootNavList.style.visibility = 'visible';
    const link = parentList.closest('li').querySelector('a');
    // console.log('link', link);
    rootNavList.addEventListener(
      'transitionend',
      () => {
        parentList.style.visibility = 'hidden';
        parentList.style.display = 'none';
        link.focus();
      },
      { once: true },
    );
  });
  return btn;
};

navLinks.forEach((link) => {
  /** @type {HTMLElement} */
  // @ts-ignore
  const sibling = link.nextElementSibling;
  if (sibling) {
    link.parentElement.classList.add('sub');
    /** @const {Number} */
    const level = +link.parentElement.getAttribute('data-level');
    const listEl = document.createElement('li');
    listEl.appendChild(createBackBtn(link, level));
    sibling.prepend(listEl);
    sibling.style.visibility = 'hidden';

    const handleInteract = () => {
      /** @type {NodeListOf<HTMLUListElement>} */
      const allLevelSiblings = document.querySelectorAll(
        `[data-level="${level < 2 ? level : ''}"] ul`,
      );
      allLevelSiblings.forEach((/** @type {HTMLElement} */ sibling) => {
        sibling.style.visibility = 'hidden';
        sibling.style.display = 'none';
        if (link.parentElement !== sibling.parentElement) {
          sibling.parentElement.classList.remove('open');
        }
      });
      if (level < 2) {
        rootNavList.style.transform = `translateX(${-1 * level * 100}%`;
        sibling.style.visibility = 'visible';
        sibling.style.display = 'block';
        const btn = sibling.querySelector('button');
        btn.style.visibility = 'visible';
        const rootLevelLinks = rootNavList.querySelectorAll(
          `[data-level="${level}"] > a`,
        );
        rootLevelLinks.forEach((link) => {
          link.setAttribute('inert', '');
        });
        rootNavList.addEventListener('transitionend', () => {
          btn.focus();
        });
      } else {
        if (link.parentElement.classList.contains('open')) {
          sibling.style.visibility = 'hidden';
          sibling.style.display = 'none';
          link.setAttribute('aria-expanded', 'false');
          // console.log(btn);
        } else {
          sibling.style.visibility = 'visible';
          sibling.style.display = 'block';
          link.setAttribute('aria-expanded', 'true');
          // console.log(btn);
        }
        link.parentElement.classList.toggle('open');
      }
    };

    const handleInteractKey = (event) => {
      const { code, type } = event;
      console.log(code);
      if (type !== 'keydown') return;
      if (code === 'Enter' || code === 'Space') {
        event.preventDefault();
        handleInteract();
      }
    };

    link.addEventListener('click', handleInteract);
    link.addEventListener('keydown', handleInteractKey);
  }

  if (link.parentElement.classList.contains('active')) {
    const btn = link.parentElement.querySelector('button');
    if (btn) {
      btn.style.visibility = 'visible';
    }
    if (+link.parentElement.getAttribute('data-level') > 2) {
      getParentsBySelector(link, 'li')[1].classList.add('open');
    }
    if (+link.parentElement.getAttribute('data-level') > 1) {
      getParentsBySelector(link, 'ul').forEach(
        (/** @type {HTMLElement} */ item) => {
          item.style.visibility = 'hidden';
          item.style.display = 'block';
        },
      );
    }
  }

  /**
   * @type {Element[]}
   */
  const lastActiveListItem = [
    ...document.querySelectorAll('.navigation li.active'),
  ].slice(1);
  lastActiveListItem.forEach((item) => {
    item.parentElement.closest('ul').style.visibility = 'visible';
  });
});

/**
 *
 * @param {HTMLElement} element
 * @param {String} selector
 * @returns {Element[]}
 */
function getParentsBySelector(element, selector) {
  let collection = [element.parentElement.closest(selector)];
  while (
    collection[collection.length - 1].parentElement.closest(selector) !== null
  ) {
    collection.push(
      collection[collection.length - 1].parentElement.closest(selector),
    );
  }
  return collection;
}

/**
 *
 * @param {KeyboardEvent} event
 * @returns {void}
 */
const handleClose = (event) => {
  const { code, type } = event;
  if (type === 'keydown' && code !== 'Escape') return;
  document.body.classList.remove('show-nav');
  toggler?.focus();
};

toggler?.addEventListener('click', () => {
  document.body.classList.add('show-nav');
  closeBtn?.focus();
});

closeBtn?.addEventListener('click', handleClose);
navElement?.addEventListener('keydown', handleClose);

const resizeObserver = new ResizeObserver((entries) => {
  const navWrapper = navElement.querySelector('.navigation-wrapper');
  const body = entries[0].target.getBoundingClientRect();
  // console.log(`body size: ${body.width}px x ${body.height}px`);
  if (body.width <= 1020) {
    // console.log('mobile');
    navWrapper.setAttribute('aria-label', 'Navigation');
    navWrapper.setAttribute('aria-modal', 'true');
    navWrapper.setAttribute('role', 'dialog');
  } else {
    // console.log('desktop');
    navWrapper.removeAttribute('aria-label');
    navWrapper.removeAttribute('aria-modal');
    navWrapper.removeAttribute('role');
  }
});
resizeObserver.observe(document.body);
