import { LitElement, html } from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { debounce } from './utils.js';
import { $t } from './l10n.js';
import * as constant from './constants.js';

class FilterList extends LitElement {
  static properties = {
    language: { type: String },
    state: { type: Object },
  }

  createRenderRoot = () => {
    return this;
  };

  constructor() {
    super();
    this.state = constant.DEFAULT_STATE;
  }

  init = () => {
    this.fetchPseudoCategories();
    // this.fetchJury();
    this.fetchResults();
  };

  connectedCallback() {
    super.connectedCallback();
    this.init();
  }

  fetchJury = () => {
    fetch(constant.API_URI, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: `
        {
          entries(
            section: "personen",
            relatedToEntries: {section: "${constant.SECTION_NAME}"}
          ) {
            id
            title
          }
        }
        `,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        this.state = {
          ...this.state,
          persons: [...this.state.persons, ...data.data.entries],
        };
      });
  };

  fetchPseudoCategories = () => {
    fetch(constant.API_URI, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: `
        {
          entries(
            section: "${constant.SECTION_NAME}",
            hasDescendants: true,
            level: 1
            site: "${this.language}"
          ) {
            title
            id
          }
          categories(group: "fundingStatus") {
            id
            title
          }
        }
        `,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        this.state = {
          ...this.state,
          pseudoCategories: [
            ...this.state.pseudoCategories,
            ...data.data.entries,
          ],
          status: [...this.state.status, ...data.data.categories],
        };
      });
  };

  composeFilterString = (status) => {
    switch (status) {
      case 'open':
        return `dateFundingProgrammeDeadline: ">${constant.TODAY_ISO}"`;
      case 'closed':
        return `dateFundingProgrammeDeadline: "<${constant.TODAY_ISO}"`;
      default:
        return '';
    }
  };

  fetchResults = () => {
    fetch(constant.API_URI, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: `
        {
          entries(
            section: "${constant.SECTION_NAME}"
            search: "${this.state.query}"
            limit: ${constant.QUERY_LIMIT}
            descendantOf: ${this.state.descendantOf}
            level: 2
            site: "${this.language}"
            ${
              this.state.filterByDate
                ? this.composeFilterString(this.state.filterByDate)
                : ''
            }
            relatedTo: ${
              Object.values(this.state.relatedTo).join().length > 0
                ? '["and",' + Object.values(this.state.relatedTo).join() + ']'
                : null
            }
            leaves: false
          ) {
            title
            url
            ... on fundingProgrammes_programme_Entry {
              id
              dateFundingProgrammeDeadline
              fundingDeadlines {
                ... on fundingDeadlines_BlockType {
                  roundTitle
                  submissionStart
                  submissionDeadline
                  programmeDeadline
                }
              }
              image {
                id
                url
                width
                height
              }
              detailsShort {
                ... on detailsShort_BlockType {
                  headline
                  text
                }
              }
            }
          }
        }
        `,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        this.state = { ...this.state, ...data.data };
        console.log(this.state);
      });
  };

  onChange = (event) => {
    this.state = {
      ...this.state,
      ...{ query: event.target.value },
    };
    this.fetchResults();
  };

  formatDate = (date) =>
    new Date(date).toLocaleDateString('de-DE', {
      // weekday: 'none',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });

  getStatus = (item) =>
    new Date(item.dateFundingProgrammeDeadline) - new Date().setHours(0,0,0,0)>= 0 ? 'open' : 'closed';

  getSingleStatus = (item) => {
    if (constant.TODAY_ISO < item.submissionStart)
      return 'yellow';
    
    if (constant.TODAY_ISO  > item.submissionStart && constant.TODAY_ISO < item.submissionDeadline)
      return 'green';
    
    if (constant.TODAY_ISO > item.submissionDeadline && constant.TODAY_ISO < item.programmeDeadline)
      return 'yellow';

    if (constant.TODAY_ISO > item.programmeDeadline)
      return 'red';
  };
  
  onCategoryClick = (event, id) => {
    event.preventDefault();
    this.state = {
      ...this.state,
      descendantOf: this.state.descendantOf !== id ? id : 0,
    };
    this.fetchResults();
  };

  pseudoCategories = () => {
    return this.state.pseudoCategories.length > 0
      ? html`
          <div 
            role="group"
            aria-labelledby="categories-label"
            >
            <p id="categories-label">
              <small>
                ${$t(this.language, 'CATEGORIES_LABEL')}
              </small>
            </p>
            <ul style="list-style:none;padding-left:0;margin:0">
              ${this.state.pseudoCategories.map(
                (item) =>
                  html`
                    <li style="display:inline-block">
                      <button
                      aria-pressed="${this.state.descendantOf === item.id}"
                      class="btn ${this.state.descendantOf === item.id ? 'active' : ''}"
                      @click=${(event) => this.onCategoryClick(event, item.id)}
                      >
                      ${item.title}
                    </button>
                  </li>  
                  `
              )}
            </ul>
          </div>
        `
      : ``;
  };

  onOrderByChange = (event) => {
    this.state = {
      ...this.state,
      ...{ orderBy: event.target.value },
    };
    this.fetchResults();
  };

  orderBy = () => html`
    <select @change=${this.onOrderByChange}>
      ${constant.ORDER_BY_ITEMS.map(
        (item) => html` <option value="${item}">${item}</option> `
      )}
    </select>
  `;

  onResetClick = () => {
    this.state = {
      ...this.state,
      descendantOf: 0,
      filterByDate: '',
      query: '',
    };
    this.fetchResults();
  };

  onStatusClick = (event, item) => {
    event.preventDefault();
    this.state = {
      ...this.state,
      ...{ filterByDate: this.state.filterByDate !== item ? item : '' },
    };
    this.fetchResults();
  };

  status = () => html`
    <div
      role="group"
      aria-labelledby="status-label"
      >
      <p id="status-label">
        <small>Status</small>
      </p>
      <ul style="list-style:none;padding-left:0;margin:0">
        ${constant.STATUS_BUTTONS.map(
          (item) => html`
            <li style="display:inline-block">
              <button
                aria-pressed="${this.state.filterByDate === item}"
                class="btn ${this.state.filterByDate === item ? 'active' : ''}"
                @click=${(event) => this.onStatusClick(event, item)}
              >
                ${$t(this.language, item)}
              </button>
            </li>
          `
        )}
      </ul>
    </div>
  `;

  onPersonsChange = (event) => {
    this.state = {
      ...this.state,
      ...{
        relatedTo: {
          jury: event.target.value ? [event.target.value] : [],
        },
      },
    };
    this.fetchResults();
  };

  persons = () => html`
    <select @change=${this.onPersonsChange}>
      <option value="">--- Jury ---</option>
      ${this.state.persons.map(
        (person) => html`
          <option
            ?selected=${this.state.relatedTo.jury.includes(+person.id)}
            value="${person.id}"
          >
            ${person.title}
          </option>
        `
      )}
    </select>
  `;

  listItem = (item) => {
    const status = this.getStatus(item);
    return html`
    <li class="quartett-item ${status}">
      <h2 style="margin-bottom: 2rem">${item.title}</h2>
      <div style="order:-1" class="logocircle">
        ${item.image?.[0] ? html`
          <img 
            alt
            width="${item.image[0].width}"
            height="${item.image[0].height}"
            src="${item.image[0].url}" />
          ` : null
        }
      </div>
      <div class="body">
        <p style="margin-bottom: 1rem">
          <button 
            class="btn btn-mini"
            @click=${(event) => this.onStatusClick(event, status)}
            >
            <small>
              ${$t(this.language, status)
              }
            </small>
            </button>
        </p>
        <small>
          <dl class="table">
            <dt>${$t(this.language, 'SUBMISSION_DEADLINES')}</dt>
            <dd>
              <ol>
                ${item.fundingDeadlines?.map(
                  (item) => html`
                    <li style="text-decoration:${new Date(item.submissionDeadline) - new Date().setHours(0,0,0,0) < 0 ? 'none' : 'none'}">
                      ${this.formatDate(item.submissionDeadline)} 
                </li>
                  `
                )}
              </ol>
            </dd>
            ${item.detailsShort?.slice(0, 2).map(
              (detail) => html`
                <dt>${detail.headline}</dt>
                <dd>${unsafeHTML(detail.text)}</dd>
              `
            )}
          </dl>
        </small>
        ${item.detailsShort?.slice(2, 4).map(
          (detail) => html`
            <h3 class="h4" style="margin-bottom:.5rem">${detail.headline}</h3>
            <small>
              <div style="margin-bottom:1.5rem">${unsafeHTML(detail.text)}</div>
            </small>
          `
        )}
      </div>
      <a href="${item.url}" style="text-align:center" class="btn btn-small">${$t(this.language, 'moreinfo')}</a>
    </li>
  `};

  list = () => html`
    <p aria-live="polite" style="margin-top:2rem">
      ${this.state.entries.length > 0 
        ? 
          `
            ${this.state.entries.length} 
            ${$t(this.language, this.state.entries.length > 1 ? 'FILTER_RESULTS' : 'FILTER_RESULT')} 
          ` 
        : 
          `
            ${$t(this.language, 'NO_FILTER_RESULT')}
          `
      }
    </p>
    <ul class="quartett">
      ${this.state.entries.map((item) => html`${this.listItem(item)}`)}
    </ul>
  `;

  render = () => {
    return html`
      ${this.pseudoCategories()}
      <div style="display:grid;grid-template-columns: 1fr auto">
        ${this.status()}
        <div style="align-self:flex-end">
          <p>
            <button 
              type="reset"
              class="btn btn-link"
              @click=${this.onResetClick}
              >
              <span>
                ${$t(this.language, 'RESET_BTN_LABEL')}
              </span>
            </button>
          </p>
        </div>
      </div>
      <form action="/search/results">
        <div class="wrapper">
          <p>
            <label
              for="search"
              >
              <small>
                ${$t(this.language, 'SEARCH_LABEL')}
              </small>
            </label>
          </p>
          <input
            type="search"
            name="search"
            id="search"
            class="form-control"
            placeholder="${$t(this.language, '')}"
            .value=${this.state.query}
            @change=${debounce(this.onChange, 500)}
            @keyup=${debounce(this.onChange, 500)}
            @input=${debounce(this.onChange, 500)}
          />
          ${this.list()}
        </div>
      </form>
    `;
  };
}

customElements.define('filter-list', FilterList);
