import Tobii from '@midzer/tobii';
import '@midzer/tobii/dist/tobii.min.css';

import { getUserLanguage } from '../lib/utils.js';

const l10n = {
  close: {
    de: 'Ansicht schließen',
    en: 'Close the dialog',
  },
  zoom: {
    de: 'Foto zoomen',
    en: 'Zoom the photo',
  },
  next: {
    de: 'Zum nächsten Bild',
    en: 'Go to the next photo',
  },
  prev: {
    de: 'Zum vorherigen Bild',
    en: 'Go to the previous photo',
  },
  error: {
    de: 'Dieses Foto kann nicht geladen werden',
    en: 'The photo cannot be loaded',
  },
  of: {
    de: ' von ',
    en: ' of ',
  },
};

const $t = (name, lang = getUserLanguage()) =>
  l10n[name]?.[lang] ? l10n[name][lang] : name;

const tobii = new Tobii({
  selector: '[data-pswp-src]',
  zoom: false,
  nav: true,
  captionsSelector: 'img',
  captionAttribute: 'data-caption',
  captionHTML: true,
  closeLabel: $t('close'),
  navLabel: [$t('prev'), $t('next')],
});

const lightboxImageLinks = document.querySelectorAll(
  '.lightbox [data-pswp-src]',
);

lightboxImageLinks.forEach((link) => {
  link.setAttribute('role', 'button');
  link.setAttribute('tabindex', '0');
  link.removeAttribute('href');
  link.style.cursor = 'pointer';
  link.addEventListener('keydown', (event) => {
    const { code } = event;
    if (code !== 'Enter' && code !== 'Space') return;
    event.preventDefault();
    event.target.click();
  });
});

const lightboxBtns = document.querySelectorAll('.lightbox button');

lightboxBtns.forEach((btn) => {
  btn.onclick = (event) => {
    tobii.open(0);
  };
});
