import { pubSub } from '../lib/pubsub.js';
import { getUserLanguage } from '../lib/utils.js';

let hasAnimations = true;

pubSub.subscribe((payload) => {
  hasAnimations = payload.hasAnimations;
});

const l10n = {
  next: {
    de: 'Nächstes',
    en: 'Next',
  },
  previous: {
    de: 'Vorheriges',
    en: 'Previous',
  },
  gotopage: {
    de: 'Gehe zu Seite',
    en: 'Move to page',
  },
  listcontrol: {
    de: 'Listensteuerung',
    en: 'Listcontrol',
  },
  page: {
    de: 'Ansicht Seite',
    en: 'View Page',
  },
  of: {
    de: 'von',
    of: 'of',
  },
};

const $t = (name, lang = getUserLanguage()) =>
  l10n[name]?.[lang] ? l10n[name][lang] : name;

class MediaScroller {
  constructor(selector) {
    this.collection = document.querySelectorAll(selector);
    this.init();
  }

  init() {
    this.collection.forEach((item) => {
      const scroller = item.querySelector('[data-media-scroller-list]');
      const liveRegion = document.createElement('small');
      liveRegion.setAttribute('aria-live', 'polite');
      const indicatorList = document.createElement('ul');
      indicatorList.classList.add('mediascroller-indicators');
      indicatorList.setAttribute('aria-label', $t('listcontrol'));
      const indicatorListWrapper = document.createElement('div');
      indicatorListWrapper.classList.add('mediascroller-indicators-wrapper');
      indicatorListWrapper.appendChild(indicatorList);
      const length = scroller.children.length;
      const activeitems = [];
      let controls = false;

      function showControls() {
        scroller.parentElement
          .querySelectorAll('[class^="mediascroller-control-"]')
          .forEach((item, index) => {
            let hidecontrols;
            hidecontrols =
              !controls ||
              (index === 0
                ? activeitems[0]
                : activeitems[activeitems.length - 1]);
            item.classList.toggle('inactive', hidecontrols);
          });
        indicatorList.classList.toggle('inactive', !controls);
      }

      function activateIndicator() {
        indicatorList.childNodes.forEach((indicator, i) => {
          const button = indicator.childNodes[0];
          button.classList.toggle('active', activeitems[i]);
          button.setAttribute('aria-current', activeitems[i]);
        });
        controls = activeitems.filter((item) => !item).length > 0;
        showControls();
      }
      const observer = new IntersectionObserver(onIntersectionObserved, {
        root: scroller,
        threshold: 0.6,
      });

      function onIntersectionObserved(entries) {
        entries.forEach((entry) => {
          const index = Array.from(entry.target.parentElement.children).indexOf(
            entry.target,
          );
          activeitems[index] = entry.isIntersecting;
        });
        activateIndicator();
        setVisibility();
      }

      function setVisibility() {
        const lf = new Intl.ListFormat(getUserLanguage());
        const activePages = [];
        activeitems.forEach((item, i) => {
          if (item) activePages.push((i + 1).toString());
        });
        [...scroller.children].forEach((item, i) => {
          item.classList.add('transition-opacity', 'duration-1000');
          item.classList.toggle('visible', activeitems[i]);
          item.classList.toggle('opacity-100', activeitems[i]);
          item.classList.toggle('invisible', !activeitems[i]);
          item.classList.toggle('opacity-0', !activeitems[i]);
        });
        liveRegion.innerText = `${$t('page')} ${lf.format(activePages)} ${$t(
          'of',
        )} ${activeitems.length}.`;
      }

      [-1, 1].forEach((arrow) => {
        const arrowElement = document.createElement('button');
        const orientation = arrow === -1 ? 'previous' : 'next';
        arrowElement.classList.add(`mediascroller-control-${orientation}`);
        arrowElement.innerHTML = `<span class="icon-${orientation}" aria-hidden="true"></span><span class="visually-hidden">${$t(
          orientation,
        )}</span>`;
        scroller.insertAdjacentElement('beforebegin', arrowElement);

        arrowElement.addEventListener('click', () => {
          const scrollLeft =
            scroller.scrollLeft +
            arrow * Math.floor(scroller.scrollWidth / length);
          scroller.scrollTo({
            left: scrollLeft,
            behavior: hasAnimations ? 'smooth' : 'instant',
          });
        });
      });
      Array.from(scroller.children).forEach((item, i) => {
        observer.observe(item);
        const indicator = document.createElement('button');
        indicator.setAttribute('aria-label', $t('gotopage') + ' ' + (i + 1));
        const indicatorListElement = document.createElement('li');
        indicatorListElement.append(indicator);
        indicatorList.append(indicatorListElement);

        indicator.addEventListener('click', (event) => {
          event.preventDefault();
          const scrollLeft = Math.floor(scroller.scrollWidth * (i / length));
          scroller.scrollTo({
            left: scrollLeft,
            behavior: hasAnimations ? 'smooth' : 'instant',
          });
        });
      });
      indicatorListWrapper.insertAdjacentElement('beforeEnd', liveRegion);
      scroller.insertAdjacentElement('beforeBegin', indicatorListWrapper);
    });
  }
}
const mediaScroller = new MediaScroller('[data-media-scroller]');
Object.freeze(mediaScroller);
export { mediaScroller };
