const collapseBtns = document.querySelectorAll('[data-bs-toggle="collapse"]');
const show = (element) => {
  element.isTransitioning = true;
  if (element.classList.contains('collapsing')) return;
  element.classList.remove('collapse');
  element.classList.add('collapsing');
  element.style.height = 0;
  setTimeout(() => {
    element.style.height = `${element.scrollHeight}px`;
  }, 1);
  element.addEventListener('transitionend', function _handleTransitionShow() {
    element.classList.remove('collapsing');
    element.classList.add('collapse', 'show');
    element.removeEventListener('transitionend', _handleTransitionShow, false);
    element.isTransitioning = false;
  });
};
const hide = (element) => {
  element.isTransitioning = true;
  if (element.classList.contains('collapsing')) return;
  element.style.height = `${element.scrollHeight}px`;
  element.classList.remove('collapse', 'show');
  element.classList.add('collapsing');
  setTimeout(() => {
    element.style.height = 0;
  }, 1);
  element.addEventListener('transitionend', function _handleTransitionHide() {
    element.classList.remove('collapsing');
    element.classList.add('collapse');
    element.classList.remove('show');
    element.removeEventListener('transitionend', _handleTransitionHide, false);
    element.isTransitioning = false;
  });
};
collapseBtns.forEach((btn) => {
  const target = btn.getAttribute('data-bs-target');
  const targetElement = document.querySelector(target);
  btn.addEventListener('click', (event) => {
    event.preventDefault();
    if (targetElement.isTransitioning) return;
    const isExpanded = btn.getAttribute('aria-expanded') === 'true';
    if (isExpanded) {
      hide(targetElement);
    } else {
      show(targetElement);
    }
    btn.setAttribute('aria-expanded', !isExpanded);
  });
});
