import { LitElement, html, nothing } from 'lit';
import { repeat } from 'lit/directives/repeat.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { fetchOption } from './api.js';
import { icons } from './icons.js';
import { trl } from './l10n.js';
import { displayFilesize, debounce } from './utils.js';

const GRAPHQL_ENDPOINT = '/api';
const LAYOUT_OPTIONS = ['list', 'grid'];
const DEFAULT_STATE = {
  categories: [],
  data: [],
  filter: {
    categories: '',
    relatedTo: '',
    searchString: '',
  },
  isReady: false,
  layout: LAYOUT_OPTIONS[1],
};

export class Downloads extends LitElement {
  static get properties() {
    return {
      // attributes
      categories: { type: String },
      language: { type: String },
      site: { type: String },
      siteUrl: { type: String },
      // component state
      state: { type: Object },
    };
  }

  connectedCallback() {
    super.connectedCallback();
    this.init();
  }

  createRenderRoot = () => {
    return this;
  };

  init = () => {
    this.state = { ...DEFAULT_STATE };
    this.state = {
      ...this.state,
      filter: { ...this.state.filter, categories: this.categories },
    };
    this.fetchData(this.state.filter);
  };

  fetchData = (filter) => {
    const uri = this.siteUrl + GRAPHQL_ENDPOINT;
    fetch(uri, fetchOption(this.site, filter))
      .then((res) => res.json())
      .then((data) => {
        if (data.errors) {
          throw data.errors[0].message;
        }
        this.state = {
          ...this.state,
          data: data.data.entries,
          categories: data.data.categories,
          isReady: true,
        };
        // console.log(this.state);
      })
      .catch((error) => {
        console.error('Fetch API: ', error);
      });
  };

  onChange = (event) => {
    this.state = {
      ...this.state,
      filter: { ...this.state.filter, searchString: event.target.value },
    };
    this.fetchData(this.state.filter);
  };

  onCategoryClick = (event, id) => {
    event.preventDefault();
    this.state = {
      ...this.state,
      filter: {
        ...this.state.filter,
        relatedTo: this.state.filter.relatedTo !== id ? id : '',
      },
      //layout: event.target.innerText === 'Magazin' ? 'grid' : this.state.layout,
    };
    this.fetchData(this.state.filter);
  };

  list = (entries) => html`
    <ul class="mt-5 list">
      ${repeat(
        entries,
        (item) => item.id,
        (item) => html`
          <li class="download-item">
            <h2 class="downloadTitle">${unsafeHTML(item.title)}</h2>
            <div style="text-transform:uppercase;justify-self:end">
              ${!item.website ? item.publication[0]?.extension : nothing}
            </div>
            <div style="justify-self:end">
              ${!item.website
                ? displayFilesize(item.publication[0]?.size)
                : nothing}
            </div>
            <div style="justify-self:end">
              ${item.website
                ? html`
                    <a
                      style="margin:0"
                      class="btn btn-small stretched-link"
                      target="_blank"
                      href="${item.website}"
                    >
                      ${trl(this.language, 'LINK')}
                    </a>
                  `
                : html`
                    <a
                      style="margin:0"
                      class="btn btn-small stretched-link"
                      download
                      title="Download ${item.publication[0]?.extension.toUpperCase()}"
                      href="${item.publication[0]?.url}"
                    >
                      Download
                    </a>
                  `}
            </div>
          </li>
        `,
      )}
    </ul>
  `;

  grid = (entries) => html`
    <ul class="mt-5 grid">
      ${repeat(
        entries,
        (item) => item.id,
        (item) => html`
          <li class="download-item">
            <div
              class="item-body"
              style="order:1"
            >
              <h2
                class="downloadTitle"
                style="margin-bottom: 0.5rem"
              >
                ${unsafeHTML(item.title)}
              </h2>
              <p>
                <small> ${unsafeHTML(item.subtitle)} </small>
              </p>
              <p>
                <small> ${item.publisher} </small>
              </p>
            </div>
            <div style="order:0;aspect-ratio:210/297">
              ${item.publicationPreview[0]
                ? html` <img
                    style="object-fit:cover;width:100%;height:100%;border:1px solid #ddd"
                    loading="lazy"
                    alt
                    src="${item.publicationPreview[0]?.url}"
                  />`
                : nothing}
            </div>
            <div
              style="align-self:end;justify-self:stretch;order:2"
              class="item-body"
            >
              ${item.website
                ? html`
                    <a
                      style="color:inherit;margin:0;display:block;text-align:center"
                      class="btn btn-small stretched-link"
                      target="_blank"
                      href="${item.website}"
                    >
                      ${trl(this.language, 'LINK')}
                    </a>
                  `
                : html`
                    <a
                      style="color:inherit;margin:0;display:block;text-align:center"
                      class="btn btn-small stretched-link"
                      download
                      title="Download ${item.publication[0]?.extension.toUpperCase()}"
                      href="${item.publication[0]?.url}"
                    >
                      Download
                      <!-- <span style="text-transform:uppercase;justify-self:end">
                        ${item.publication[0]?.extension}
                      </span>
                      ${displayFilesize(item.publication[0]?.size)} -->
                    </a>
                  `}
            </div>
          </li>
        `,
      )}
    </ul>
  `;

  results = (entries) => html`
    <p
      style="margin-top:2rem"
      aria-live="polite"
    >
      ${entries.length > 0
        ? `${entries.length} ${trl(this.language, 'FILTER_RESULTS')}
        `
        : trl(this.language, 'NO_RESULTS')}
    </p>
    ${this[this.state.layout](entries)}
  `;

  filter = (categories) => html`
    <div
      role="group"
      aria-label="Filter"
      class="dwc-filter-btns"
    >
      <ul>
        ${categories.length > 1
          ? categories.map(
              (item) => html`
                <li>
                  <button
                    @click=${(event) => this.onCategoryClick(event, item.id)}
                    aria-pressed="${item.id === this.state.filter.relatedTo}"
                    class="btn btn-sm ${item.id === this.state.filter.relatedTo
                      ? 'active'
                      : ''}"
                  >
                    ${item.title}
                  </button>
                </li>
              `,
            )
          : nothing}
      </ul>
      <span> ${this.resetButton()} ${this.layoutButtons()} </span>
    </div>
    <label for="search">
      <small>${trl(this.language, 'SEARCH')}</small>
    </label>
    <input
      id="search"
      type="search"
      name="search"
      class="form-control"
      style=""
      .value=${this.state.filter.searchString}
      placeholder=${trl(this.language, '')}
      @search=${this.onChange}
      @keyup=${debounce(this.onChange, 800)}
    />
  `;

  onResetButtonClick = (event) => {
    event.preventDefault();
    this.state = {
      ...this.state,
      filter: {
        ...this.state.filter,
        relatedTo: '',
        searchString: '',
      },
    };
    this.fetchData(this.state.filter);
  };

  resetButton = () => html`
    <button
      type="reset"
      class="btn btn-sm btn-link"
      @click=${this.onResetButtonClick}
    >
      <span> ${trl(this.language, 'RESET_BTN_LABEL')} </span>
    </button>
  `;

  onLayoutButtonsClick = (event, item) => {
    event.preventDefault();
    this.state = { ...this.state, layout: item };
  };

  layoutButtons = () => html`
    ${LAYOUT_OPTIONS.map(
      (item) =>
        html`
          <button
            style="background-color:transparent;border:0"
            title=${trl(this.language, item)}
            @click=${(event) => this.onLayoutButtonsClick(event, item)}
            ?disabled=${this.state.layout === item}
          >
            ${icons[item]}
          </button>
        `,
    )}
  `;

  render = () => {
    const { categories, data, isReady } = this.state;
    return isReady && categories.length
      ? html` ${this.filter(categories)} ${this.results(data)} `
      : html`<p aria-live="polite">
          ${trl(this.language, 'NO_CATEGORY_RESULTS')}
        </p>`;
  };
}

customElements.define('wc-downloads', Downloads);
