import * as THREE from 'three';
import gsap from 'gsap';
import { isCreepyAndroidBrowser } from '../lib/utils.js';
import { pubSub } from '../lib/pubsub.js';

let hasAnimations = true;

const CANVAS_SCALE_FACTOR = 3.2;
const OBJECT_SPREAD = 2.15;

const ROTATIONS = [-1, -1, -1];
const DURATIONS = [3, 8, 2];
const DELAYS = ['+=2.4', '', '<+=2.8'];

const images = [
  '/static/fonds.webp',
  '/static/darstellende.webp',
  '/static/kuenste.webp',
];
const objects = [];

const logobox = document.querySelector('.logo > a');
const logosvg = logobox.querySelector('svg');
const canvas = document.createElement('canvas');
const renderer = new THREE.WebGLRenderer({
  canvas,
  alpha: true,
  antialias: true,
});

const fov = 75;
const aspect = 2; // the canvas default
const near = 0.1;
const far = 200;
const camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
const scene = new THREE.Scene();
const group = new THREE.Group();

canvas.setAttribute('id', 'logo-animation');
canvas.setAttribute('aria-hidden', 'true');

camera.position.z = 14;
scene.add(group);

const addObject = (x, y, obj) => {
  obj.position.x = x;
  obj.position.y = y;
  group.add(obj);
  objects.push(obj);
};

const createMaterial = (index) => {
  const material = new THREE.MeshBasicMaterial({
    side: THREE.DoubleSide,
    transparent: true,
    depthWrite: false,
  });
  const texture = new THREE.TextureLoader().load(images[index]);
  material.map = texture;
  return material;
};

const tl = gsap.timeline({ repeat: -1, repeatDelay: 40 });
images.forEach((string, index) => {
  const geometry = new THREE.PlaneBufferGeometry(20.14, 2.14);
  const mesh = new THREE.Mesh(geometry, createMaterial(index));
  geometry.computeBoundingBox();
  geometry.boundingBox.getCenter(mesh.position).multiplyScalar(-1);
  const vector = geometry.boundingBox
    .getCenter(mesh.position)
    .multiplyScalar(-1);
  geometry.translate(vector.x, vector.y, vector.z);
  addObject(0, (index - 1) * -OBJECT_SPREAD, mesh);
  let rotation = { x: 0, y: 0, z: 0 };
  tl.to(
    rotation,
    {
      x: Math.PI * 2 * ROTATIONS[index],
      y: Math.PI * 2 * ROTATIONS[index],
      z: Math.PI * 2 * ROTATIONS[index],
      duration: DURATIONS[index],
      ease: 'expo.inOut',
      onUpdate: function () {
        mesh.rotation.y = rotation.y;
      },
    },
    DELAYS[index],
  );
});

const resizeRendererToDisplaySize = (renderer) => {
  const canvas = renderer.domElement;
  const pixelRatio = window.devicePixelRatio;
  const width = (canvas.clientWidth * pixelRatio) | 0;
  const height = (canvas.clientHeight * pixelRatio) | 0;
  const logorect = logobox.getBoundingClientRect();
  const needResize =
    canvas.width !== width ||
    canvas.height !== height ||
    canvas.style.top !==
      logorect.top - (canvas.clientHeight - logorect.height) / 2;

  if (needResize) {
    renderer.setSize(width, height, false);
    canvas.style.width = logorect.width * CANVAS_SCALE_FACTOR + 'px';
    canvas.style.height = logorect.height * CANVAS_SCALE_FACTOR + 'px';
    canvas.style.top =
      logorect.top - (canvas.clientHeight - logorect.height) / 2 + 'px';
    canvas.style.left =
      logorect.left - (canvas.clientWidth - logorect.width) / 2 + 'px';
  }
  return needResize;
};

const render = () => {
  if (resizeRendererToDisplaySize(renderer)) {
    const canvas = renderer.domElement;
    camera.aspect = canvas.clientWidth / canvas.clientHeight;
    camera.updateProjectionMatrix();
  }
  renderer.render(scene, camera);
  requestAnimationFrame(render);
};
requestAnimationFrame(render);

if (false && !hasAnimations) {
  document.addEventListener('mousemove', tiltLogo);
}

function tiltLogo(event) {
  const normalizeMouse = {
    x: ((event.screenX - screen.width / 2) / screen.width) * 2,
    y: (-(event.screenY - screen.height / 2) / screen.height) * 2,
  };
  // console.log(normalizeMouse);
  gsap.to(group.rotation, {
    x: normalizeMouse.y / 3,
    y: normalizeMouse.x / 3,
    ease: 'power2.out',
  });
}

pubSub.subscribe((payload) => {
  hasAnimations = payload.hasAnimations;
  if (hasAnimations && !isCreepyAndroidBrowser) {
    // Start animation
    canvas.style.visibility = 'visible';
    logosvg.style.opacity = '0';
  } else {
    canvas.style.visibility = 'hidden';
    logosvg.style.opacity = '1';
  }
});

if (hasAnimations && !isCreepyAndroidBrowser) {
  // Start animation
  canvas.style.visibility = 'visible';
} else {
  // show fallback logo
  logosvg.style.opacity = '1';
}

document.body.appendChild(canvas);
