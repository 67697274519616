import { $t } from '../components/filter-list/l10n';

const searchInput = document.getElementById('searchInputFAQ');
const resetBtn = document.getElementById('resetFAQ');
const resultRegion = document.getElementById('resultFAQ');
const domItems = [...document.querySelectorAll('.accordion-item')];
const searchable = [];

const filterItems = (arr, query) => {
  return arr.filter(
    (el) => el.string.toLowerCase().indexOf(query.toLowerCase()) !== -1,
  );
};

const search = (str) => {
  const result = filterItems(searchable, str);
  domItems.forEach((item) => {
    item.style.display = 'none';
    const headline = item.closest('.row').querySelector('h2');
    if (headline) headline.style.display = 'none';
  });
  result.forEach((item) => {
    const element = document.getElementById(item.id);
    element.style.display = 'block';
    const headline = element.closest('.row').querySelector('h2');
    if (headline) headline.style.display = 'block';
  });
  const lang = document.querySelector('html').getAttribute('lang') || 'en';
  resultRegion.innerText =
    result.length > 0
      ? result.length + ' ' + $t(lang, 'FILTER_RESULTS')
      : $t(lang, 'NO_FILTER_RESULT');
};

const searchInputEventHandler = (event) => {
  search(event.target.value);
};

if (searchInput) {
  domItems.forEach((item) => {
    const id = item.getAttribute('id');
    const string = item.dataset.searchable;
    searchable.push({ id, string });
  });
  // console.log(searchable);

  searchInput.addEventListener('keyup', searchInputEventHandler);

  resetBtn.addEventListener('click', () => {
    searchInput.value = '';
    search('');
  });
}
