export const API_URI = '/api';
export const SECTION_NAME = 'fundingProgrammes';
export const QUERY_LIMIT = null;
export const ORDER_BY_ITEMS = ['dateUpdated DESC', 'dateUpdated ASC', 'score'];
export const STATUS_BUTTONS = ['open', 'closed'];
export const DEFAULT_STATE = {
  entries: [],
  pseudoCategories: [],
  descendantOf: 0,
  filterByDate: '',
  orderBy: 'dateUpdated DESC',
  persons: [],
  relatedTo: {
    jury: [],
  },
  status: [],
  query: '',
};


export const TODAY = new Date();
export const TODAY_ISO = TODAY.toISOString();
