const links = document.querySelectorAll('.accordion-body a[href^="#"]');
links.forEach((link) => {
  link?.addEventListener('click', (event) => {
    try {
      document.querySelector(link.getAttribute('href') + ' button').click();
    } catch (error) {
      console.log(
        'Something went wrong. Maybe glossary anchor link not found or malformed.',
      );
    }
  });
});
