const l10n = {
  SEARCH: {
    en: 'Search',
    de: 'Suche',
  },
  PLACEHOLDER: {
    en: 'Search publication ...',
    de: 'Publikationen durchsuchen ...',
  },
  RESET_BTN_LABEL: {
    en: 'reset',
    de: 'zurücksetzen',
  },
  FILTER_RESULTS: {
    en: 'results found',
    de: 'Ergebnisse gefunden',
  },
  NO_RESULTS: {
    en: 'No items found!',
    de: 'Ihre Filterung liefert keine Ergebnisse.',
  },
  NO_CATEGORY_RESULTS: {
    en: 'No items in selected categories at the moment.',
    de: 'Zur Zeit keine Publikationen in ausgewählten Kategorien.',
  },
  list: {
    en: 'List view',
    de: 'Listenansicht',
  },
  grid: {
    en: 'Grid view',
    de: 'Kachelansicht',
  },
  LINK: {
    en: 'Website',
    de: 'Zur Webseite',
  },
};

export const trl = (lang, name) =>
  l10n[name]?.[lang] ? l10n[name][lang] : name;
