import '@orestbida/iframemanager/dist/iframemanager.js';
import 'vanilla-cookieconsent/dist/cookieconsent.js';

// Init Iframemanager AND Cookie Consent
const iframeManager = iframemanager();
const cookieConsent = initCookieConsent();

window.initIframeManager = () => {
  iframeManager.run({
    currLang: (() =>
      document.querySelector('html').getAttribute('lang') || 'en')(),

    onChange: ({ changedServices, eventSource }) => {
      if (eventSource.type === 'click') {
        // Retrieve all accepted services:
        // const allAcceptedServices = im.getState().acceptedServices;

        /**
         * Retrieve array of already accepted services
         * and add the new service
         */
        const servicesToAccept = [
          ...cookieConsent.getUserPreferences().acceptedServices['vimeo'], //cookieconsent v3
          ...cookieConsent.getUserPreferences().acceptedServices['youtube'], //cookieconsent v3
          ...changedServices,
        ];

        cookieConsent.acceptService(servicesToAccept, 'vimeo');
        cookieConsent.acceptService(servicesToAccept, 'youtube');
      }
    },
    services: {
      youtube: {
        embedUrl: 'https://www.youtube.com/embed/{data-id}',
        // thumbnailUrl: 'https://i3.ytimg.com/vi/{data-id}/hqdefault.jpg',
        iframe: {
          allow:
            'accelerometer; encrypted-media; gyroscope; picture-in-picture; fullscreen;',
        },
        languages: {
          en: {
            notice:
              'This content is hosted by a third party. By showing the external content you accept the <a rel="noreferrer noopener" href="https://www.youtube.com/t/terms" target="_blank">terms and conditions</a> of youtube.com.',
            loadBtn: 'Activate video',
            // loadAllBtn: "Don't ask again",
          },
          de: {
            notice:
              'Dieser Inhalt wird von einem externen Dienst gehostet. Durch die Anzeige des externen Inhalts akzeptieren Sie die <a rel="noreferrer noopener" href="https://www.youtube.com/t/terms" target="_blank">Nutzungsbedingungen</a> von youtube.com.',
            loadBtn: 'Video aktivieren',
            // loadAllBtn: "DE: Don't ask again",
          },
        },
      },
      vimeo: {
        embedUrl: 'https://player.vimeo.com/video/{data-id}',
        iframe: {
          allow: 'fullscreen; picture-in-picture, allowfullscreen;',
        },
        // thumbnailUrl: async (dataId, setThumbnail) => {
        //   const url = `https://vimeo.com/api/v2/video/${dataId}.json`;
        //   const response = await (await fetch(url)).json();
        //   const thumbnailUrl = response[0]?.thumbnail_large;
        //   thumbnailUrl && setThumbnail(thumbnailUrl);
        // },
        languages: {
          en: {
            notice:
              'This content is hosted by a third party. By showing the external content you accept the <a rel="noreferrer noopener" href="https://vimeo.com/cookie_policy" target="_blank">terms and conditions</a> of vimeo.com.',
            loadBtn: 'Activate video',
            // loadAllBtn: "Don't ask again",
          },
          de: {
            notice:
              'Dieser Inhalt wird von einem externen Dienst gehostet. Durch die Anzeige des externen Inhalts akzeptieren Sie die <a rel="noreferrer noopener" href="https://www.youtube.com/t/terms" target="_blank">Nutzungsbedingungen</a> von vimeo.com.',
            loadBtn: 'Video aktivieren',
            // loadAllBtn: "DE: Don't ask again",
          },
        },
      },
    },
  });
};
initIframeManager();

// Cookie Consent
// run plugin with your configuration
cookieConsent.run({
  current_lang: 'en',
  autoclear_cookies: true, // default: false
  page_scripts: true, // default: false

  // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
  // delay: 0,                               // default: 0
  auto_language: 'document', // default: null; could also be 'browser' or 'document'
  // autorun: true,                          // default: true
  // force_consent: false,                   // default: false
  // hide_from_bots: true,                   // default: true
  // remove_cookie_tables: false             // default: false
  // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
  // cookie_expiration: 182,                 // default: 182 (days)
  // cookie_necessary_only_expiration: 182   // default: disabled
  // cookie_domain: location.hostname,       // default: current domain
  // cookie_path: '/',                       // default: root
  // cookie_same_site: 'Lax',                // default: 'Lax'
  // use_rfc_cookie: false,                  // default: false
  // revision: 0,                            // default: 0

  onFirstAction: function (user_preferences, cookie) {
    // callback triggered only once
  },

  onAccept: function (cookie) {
    if (cookieConsent.allowedCategory('vimeo'))
      iframeManager.acceptService('vimeo');
    if (cookieConsent.allowedCategory('youtube'))
      iframeManager.acceptService('youtube');
  },

  onChange: function (cookie, changed_preferences) {
    if (!cookieConsent.allowedCategory('vimeo'))
      iframeManager.rejectService('vimeo');
    if (!cookieConsent.allowedCategory('youtube'))
      iframeManager.rejectService('youtube');
  },

  languages: {
    en: {
      consent_modal: {
        title: 'We use cookies!',
        description:
          'Hi, this website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only after consent. <button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>',
        primary_btn: {
          text: 'Accept all',
          role: 'accept_all', // 'accept_selected' or 'accept_all'
        },
        secondary_btn: {
          text: 'Reject all',
          role: 'accept_necessary', // 'settings' or 'accept_necessary'
        },
      },
      settings_modal: {
        title: 'Cookie preferences',
        save_settings_btn: 'Save settings',
        accept_all_btn: 'Accept all',
        reject_all_btn: 'Reject all',
        close_btn_label: 'Close',
        cookie_table_headers: [
          { col1: 'Name' },
          { col2: 'Domain' },
          { col3: 'Expiration' },
          { col4: 'Description' },
        ],
        blocks: [
          {
            title: 'Cookie usage',
            description:
              'We use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a href="#" class="cc-link">privacy policy</a>.',
          },
          {
            title: 'Performance and analytics cookies',
            description:
              'These cookies allow the website to remember the choices you have made in the past',
            toggle: {
              value: 'analytics', // your cookie category
              enabled: false,
              readonly: false,
            },
            cookie_table: [
              // list of all expected cookies
              {
                col1: 'MATOMO_SESSID', // match all cookies starting with "_ga"
                col2: 'matomo.fonds-daku.de',
                col3: '14 days',
                col4: 'Matomo Sessioncookie',
                is_regex: true,
              },
            ],
          },
          {
            title: 'Vimeo',
            description:
              'These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you',
            toggle: {
              value: 'vimeo',
              enabled: false,
              readonly: false,
            },
          },
          {
            title: 'YouTube',
            description:
              'These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you',
            toggle: {
              value: 'youtube',
              enabled: false,
              readonly: false,
            },
          },
        ],
      },
    },
    de: {
      consent_modal: {
        title: 'Wir verwenden Cookies!',
        description:
          'Hallo, diese Website verwendet essentielle Cookies, um ihren ordnungsgemäßen Betrieb zu gewährleisten, und Tracking-Cookies, um zu verstehen, wie Sie mit ihr interagieren. Letztere werden nur nach Zustimmung gesetzt. <button type="button" data-cc="c-settings" class="cc-link">Auswählen</button>',
        primary_btn: {
          text: 'Alle zulassen',
          role: 'accept_all', // 'accept_selected' or 'accept_all'
        },
        secondary_btn: {
          text: 'Alle ablehnen',
          role: 'accept_necessary', // 'settings' or 'accept_necessary'
        },
      },
      settings_modal: {
        title: 'Cookie-Einstellungen',
        save_settings_btn: 'Einstellungen speichern',
        accept_all_btn: 'Alle akzeptieren',
        reject_all_btn: 'Alle ablehnen',
        close_btn_label: 'Schließen',
        cookie_table_headers: [
          { col1: 'Name' },
          { col2: 'Domain' },
          { col3: 'Maximalalter' },
          { col4: 'Beschreibung' },
        ],
        blocks: [
          {
            title: 'Cookieverwendung',
            description:
              'Wir verwenden Cookies, um die grundlegenden Funktionen der Website zu gewährleisten und um Ihr Online-Erlebnis zu verbessern. Sie können für jede Kategorie wählen, ob Sie sich ein- oder austragen möchten. Für weitere Einzelheiten zu Cookies und anderen sensiblen Daten lesen Sie bitte die vollständige <a href="#" class="cc-link">Datenschutzerklärung</a>.',
          },
          {
            title: 'Leistungs- und Analyse-Cookies',
            description:
              'Diese Cookies ermöglichen es der Website, sich an die von Ihnen in der Vergangenheit getroffenen Auswahlen zu erinnern',
            toggle: {
              value: 'analytics', // your cookie category
              enabled: false,
              readonly: false,
            },
            cookie_table: [
              // list of all expected cookies
              {
                col1: 'MATOMO_SESSID', // match all cookies starting with "_ga"
                col2: 'matomo.fonds-daku.de',
                col3: '14 Tage',
                col4: 'Matomo Sessioncookie',
                is_regex: true,
                is_regex: true,
              },
            ],
          },
          {
            title: 'Vimeo',
            description:
              'Dieser Inhalt wird von einem externen Dienst gehostet. Durch die Anzeige des externen Inhalts akzeptieren Sie die <a rel="noreferrer noopener" href="https://vimeo.com/cookie_policy" target="_blank">Nutzungsbedingungen</a> von vimeo.com.',
            toggle: {
              value: 'vimeo',
              enabled: false,
              readonly: false,
            },
          },
          {
            title: 'YouTube',
            description:
              'Dieser Inhalt wird von einem externen Dienst gehostet. Durch die Anzeige des externen Inhalts akzeptieren Sie die <a rel="noreferrer noopener" href="https://www.youtube.com/t/terms" target="_blank">Nutzungsbedingungen</a> von youtube.com.',
            toggle: {
              value: 'youtube',
              enabled: false,
              readonly: false,
            },
          },
        ],
      },
    },
  },
});
