const { pow, round } = Math;

export const displayFilesize = (number) =>
  number / pow(1024, 2) < 1 // is less than 1 MB
    ? `${round(number / 1024)} KB`
    : `${round(number / pow(1024, 2))} MB`;

export const debounce = (fn, wait) => {
  let timer;
  return function (...args) {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      fn(...args);
      // console.log('debounced after: ', wait);
    }, wait);
  };
};

export const animationOptions = (item = {}, layout = 'list') => ({
  keyframeOptions: {
    duration: 250,
    fill: 'both',
  },
  in: [{ opacity: 0 }, { opacity: 1 }],
  out: [{ opacity: 0 }],
  stabilizeOut: true,
  id: layout + item.id,
  inId: layout + item.id,
  skipInitial: false,
});
