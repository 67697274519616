export const debounce = (fn, wait) => {
  let timer;
  return function (...args) {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      fn(...args);
      // console.log('debounced after: ', wait);
    }, wait);
  };
};

// dirty, dirty
const tabIndex = document.querySelectorAll('span[tabindex="-1"]');
tabIndex.forEach((item) => item.removeAttribute('tabindex'));
const scripts = document.querySelectorAll('script');
scripts.forEach((script) => script.removeAttribute('type'));
