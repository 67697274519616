class Modal extends HTMLElement {
  get visible() {
    return this.hasAttribute('visible');
  }

  set visible(value) {
    if (value) {
      this.setAttribute('visible', '');
    } else {
      this.removeAttribute('visible');
    }
  }

  get title() {
    return this.getAttribute('title');
  }

  set title(value) {
    this.setAttribute('title', value);
  }

  get image() {
    return this.getAttribute('image');
  }

  get imageCredits() {
    return this.getAttribute('imageCredits');
  }

  get imageAlt() {
    return this.getAttribute('imageAlt');
  }

  set image(value) {
    this.setAttribute('image', value);
  }

  get width() {
    return this.getAttribute('width');
  }

  set width(value) {
    this.setAttribute('width', value);
  }

  get height() {
    return this.getAttribute('height');
  }

  set height(value) {
    this.setAttribute('height', value);
  }

  constructor() {
    super();
  }

  initTabs(root) {
    const tabLists = root
      .querySelector('slot[name=tablist]')
      .assignedNodes({ flatten: true });
    const contents = root
      .querySelector('slot[name=tab-content]')
      .assignedNodes({ flatten: true })[0];

    tabLists.forEach((list) => {
      const toggleBtns = list.querySelectorAll('[data-bs-toggle="tab"]');
      toggleBtns.forEach((btn) => {
        const target = btn.getAttribute('data-bs-target');
        const targetElement = contents.querySelector(target);
        btn.addEventListener('click', (event) => {
          event.preventDefault();
          if (targetElement.isTransitioning) return;
          const isSelected = btn.getAttribute('aria-selected') === 'true';
          if (isSelected) return;
          toggleBtns.forEach((btn) => {
            btn.setAttribute('aria-selected', 'false');
            const target = btn.getAttribute('data-bs-target');
            contents.querySelector(target).classList.remove('active');
          });
          btn.setAttribute('aria-selected', !isSelected);
          targetElement.classList.toggle('active', !isSelected);
        });
      });
    });
  }

  connectedCallback() {
    this._render();
    this._attachEventHandlers();
    this.initTabs(this.shadowRoot);
  }

  static get observedAttributes() {
    return ['visible', 'title'];
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (name === 'title' && this.shadowRoot) {
      this.shadowRoot.querySelector('.title').textContent = newValue;
    }
    if (name === 'visible' && this.shadowRoot) {
      if (newValue === null) {
        this.shadowRoot.querySelector('.wrapper').classList.remove('visible');
        this.dispatchEvent(
          new CustomEvent('close', {
            bubbles: true,
            composed: true,
          }),
        );
        document.body.style.overflow = '';
      } else {
        this.shadowRoot.querySelector('.wrapper').classList.add('visible');
        this.dispatchEvent(
          new CustomEvent('open', {
            bubbles: true,
            composed: true,
          }),
        );
        document.body.style.overflow = 'hidden';
        const closeButton = this.querySelector('.close');
        closeButton.focus({ focusVisible: true });
      }
    }
  }

  _render() {
    const wrapperClass = this.visible ? 'wrapper visible' : 'wrapper';
    const container = document.createElement('div');
    container.innerHTML = `
      <style>
        :host {
          overflow: hidden;
        }
        .wrapper {
          position: fixed;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: var(--body-bg-color, white);
          opacity: 0;
          visibility: hidden;
          transform: scale(1.1);
          transition: visibility 0s linear .25s,opacity .25s 0s,transform .25s;
          z-index: 21;
          overflow: hidden;
        }
        .visible {
          opacity: 1;
          visibility: visible;
          transform: scale(1);
          transition: visibility 0s linear 0s,opacity .25s 0s,transform .25s;
        }
        .modal {
          font-family: var(--font-family, sans-serif);
          font-size: var(--base-font-size, 14px);
          background-color: var(--body-bg-color, white);
          height: 100%;
          box-sizing: border-box;
          display: grid;
          grid-template-rows: auto auto auto 1fr;
          grid-template-areas:
            'i' 't' 'n' 'c';
          gap: var(--gap, 1rem);
          overscroll-behavior: contain;
          overflow: auto;
          padding-bottom: 2rem;
        }
        .title {
          grid-area: t;
          font-size: var(--h1-font-size);
        }
        .subtitle {
          font-size: var(--intro-font-size);
          line-height: 120%;
          font-style: italic;
          margin: 0;
        }
        figure, .figure {
          margin: 0;
        }
        figure > div,
        .figure > div {
          position: relative;
        }
        figure small,
        .figure small {
          position: absolute;
          font-size: 0.55rem;
          right: 0;
          bottom: 0;
          padding: 0.125rem 0.5rem;
          background: var(--text-color);
          transition: 0.4s;
          color: var(--text-color-inverted);
        }
        .image {
          grid-area: i;
        }
        img {
          max-width: 100%;
          height: auto;
          display: block;
        }
        ::slotted(.nav-tabs) {
          grid-area: n;
          list-style: none;
          display: grid;
          grid-auto-flow: column;
          margin-bottom: 0 !important;
          justify-content: flex-start;
          gap: 1rem;
        }
        ::slotted(.tab-content) {
          overscroll-behavior: contain;
          grid-area: c;
          font-size: var(--intro-font-size);
          line-height: 150%;
        }
        .title, 
        ::slotted(ul.nav-tabs), 
        ::slotted(.tab-content) {
          padding: 0 1rem !important;
        }
        .close {
          position: absolute;
          top: 1rem;
          right: 1rem;
          z-index: 1;
        }
        .tab-content > .tab-pane {
          display: none;
        }
        .tab-content > .active {
          display: block;
        }
        .fade {
          transition: opacity 0.15s linear;
        }
        .fade:not(.show) {
          opacity: 0;
        }
        @media (min-width: 1020px) {
          .modal {
            grid-template-columns: 1fr 2fr;

            grid-template-rows: auto auto 1fr;
            grid-template-areas:
              't t' 'i c' 'i c';
          }
          .modal {
            padding: var(--gap, 2rem);
          }
          .title, 
          ::slotted(ul.nav-tabs), 
          ::slotted(.tab-content) {
            padding: 0 !important;
          }
          .title {
            font-size: var(--h1-font-size);
            line-height: 100%;
            padding: 0;
          }
        }
      </style>
      <div class='${wrapperClass}'>
        <slot name="close"></slot>
        <div class='modal'>
          <div class='title'>
            <slot name="title"></slot>
            <p class='subtitle'><slot name="subtitle"></slot></p>
          </div>
          <div class="image">
            <div>
              <img src="${this.image}" alt="${this.imageAlt}" width="${this.width}" height="${this.height}" />
              <small class="credits">© ${this.imageCredits}</small>
            </div>
          </div>
          <slot name="tablist"></slot>
          <slot name="tab-content"></slot>
        </div>
      </div>`;

    const shadowRoot = this.attachShadow({ mode: 'open' });
    shadowRoot.appendChild(container);
  }

  _attachEventHandlers() {
    const closeButton = this.querySelector('.close');
    closeButton.addEventListener('click', (e) => {
      this.removeAttribute('visible');
    });
  }
}
window.customElements.define('person-modal', Modal);
