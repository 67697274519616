class PubSub {
  constructor() {
    this.subscribers = [];
  }

  subscribe(subscriber) {
    if (typeof subscriber !== 'function') {
      throw new Error(
        `${typeof subscriber} is not a valid argument for subscribe method, expected a function instead`,
      );
    }
    this.subscribers = [...this.subscribers, subscriber];
  }

  unsubscribe(subscriber) {
    if (typeof subscriber !== 'function') {
      throw new Error(
        `${typeof subscriber} is not a valid argument for unsubscribe method, expected a function instead`,
      );
    }
    this.subscribers = this.subscribers.filter((sub) => sub !== subscriber);
  }

  publish(payload) {
    this.subscribers.forEach((subscriber) => subscriber(payload));
  }
}

// https://dev.to/anishkumar/design-patterns-in-javascript-publish-subscribe-or-pubsub-20gf
// kum: instance right here
export const pubSub = new PubSub();
