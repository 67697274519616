export const isPreferingReducedMotion = window.matchMedia(
  '(prefers-reduced-motion: reduce)',
).matches;

export const isPreferingDarkScheme = window.matchMedia(
  '(prefers-color-scheme: dark)',
).matches;

export const isViewportMobile = () => window.matchMedia(
  '(max-width: 1020px)',
).matches;

const ua = navigator.userAgent;
export const isCreepyAndroidBrowser =
  ua.match(/(?=.*android)(?=.*firefox)|(?=.*samsungbrowser)/i) !== null;

export const getUserLanguage = () =>
  document.documentElement.lang?.substring(0, 2) ||
  navigator.language?.substring(0, 2) ||
  'en';
