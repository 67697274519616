const DEFAULT_SITE_ID = 'de';
const SECTION = 'publications';
const CATEGORY_GROUP = 'publications';

const getRelatedFilter = (filter) => {
  const { categories, relatedTo } = filter;
  if (!categories && !relatedTo) return null;
  return `[${relatedTo.length ? relatedTo : categories.length ? categories : null}]`;
};

export const fetchOption = (site = DEFAULT_SITE_ID, filter) => {
  const query = `
  {
    entries(
      section: "${SECTION}", 
      search: "${filter.searchString}", 
      relatedTo: ${getRelatedFilter(filter)},
      orderBy: "postDate DESC")
      {
      id
      title
      ... on publications_default_Entry {
        subtitle
        publisher
        website
        publication {
          id
          url
          kind
          size
          dateCreated
          dateUpdated
          extension
          filename
          img
          width
          height
        }
        publicationPreview {
          url
          img
          width
          height
        }
      }
    }

    categories(group: "${CATEGORY_GROUP}", relatedToEntries: {section: "${SECTION}"} 
      ${filter.categories.length ? `, id: [${filter.categories}]` : ''}) {
      id
      title
    }
  }
`;
  return {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query,
    }),
  };
};
